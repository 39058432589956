/*============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/

@import "sass-lib";

@media all and (max-width: 1023px) {
  /* global
----------------------------------------------------------------------------------------------*/
  .after_clear:after,
  .wrapper:after,
  section:after {
    clear: both;
    display: block;
    content: " ";
  }
  .before_clear:before {
    clear: both;
    display: block;
    content: " ";
  }

  .wrapper {
    width: 720px;
    &.w126,
    &.det_des {
      width: 720px;
    }
  }
  .btn_round {
    line-height: 46px;
    min-width: 165px;
    &.little {
      line-height: 36px;
      padding: 0 20px;
    }
  }

  /* header
----------------------------------------------------------------------------------------------*/

  header {
    padding: 17px 0;
    .logo {
      width: 300px;
      margin-right: 0;
      position: fixed;
      left: 0;
      right: 0;
      margin: auto;
    }
    .lf_head {
      margin-top: 8px;
      .burger {
        margin-right: 15px;
        .ico_brger {
          width: 30px;
          &.act {
            span:nth-child(1) {
              left: 2px !important;
              top: 5px !important;
            }
            span:nth-child(2) {
              left: calc(50% - 2px) !important;
              top: 5px !important;
              width: 50%;
            }
            span:nth-child(5) {
              left: 2px !important;
              top: 16px !important;
            }
            span:nth-child(6) {
              left: calc(50% - 2px) !important;
              top: 16px !important;
            }
          }
        }
      }
      .ico_src {
        padding-right: 15px;
        margin-right: 15px;
      }
      .lang {
        .act {
          font-size: 13px;
        }
        .drop {
          padding: 2px 10px 10px 1px;
        }
        &.idr {
          display: none;
        }
      }
    }
    .rh_head {
      nav {
        a.btn_round {
          line-height: 35px;
          font-size: 12px;
        }
      }
    }
    &.home {
      padding-top: 32px;
    }
    &.float {
      padding-top: 17px;
      .logo {
        width: 300px;
      }
    }
  }

  .pop_src {
    height: 110px;
    form {
      input[type="text"] {
        font-size: 40px;
      }
    }
  }

  .mnu_wrap {
    top: 50%;
    nav {
      width: 45%;
      ul {
        li {
          a {
            font-size: 40px;
          }
          .sub {
            left: 100%;
            padding-left: 150px;
            padding-top: 10px;
            width: 240px;
            .line {
              top: 25px;
            }
          }
        }
      }
    }
    .bhs {
      display: block;
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      padding-top: 5px;
      .lg,
      .satuan {
        display: inline-block;
        select {
          font-size: 16px;
          padding: 10px 5px;
          background: transparent;
          color: rgba(255, 255, 255, 0.4);
          border: none;
          font-weight: 600;
          padding-right: 15px;
          background: url("../images/material/arr_lang_white.png") no-repeat
            right center;
          option {
            background: $c_adv;
          }
        }
      }
      .lg {
        margin-right: 20px;
        display: none;
      }
    }
  }

  /* homepage
----------------------------------------------------------------------------------------------*/

  .slideHome {
    .slider {
      .slide {
        .capt_slide {
          h6 {
            font-size: 16px;
            width: 70%;
          }
          h1 {
            font-size: 45px;
            line-height: 55px;
            width: 75%;
          }
        }
      }
      .slick-dots {
        li {
          height: 5px;
          button {
            height: 4px;
          }
        }
      }
    }
    &.contentslider {
      margin: 0 20px;
      margin-bottom: 50px;
    }
  }
  .bannerslide {
    .slideHome.contentslider {
      margin: 0 20px;
      margin-bottom: 50px;
    }
  }
  .pack_detail {
    .wrapper.w126 {
      width: 700px;
    }
  }
  .sec_journey {
    .left {
      float: none;
      display: block;
      margin: 0 0 10px;
      h6 {
        font-size: 16px;
        span {
          display: inline-block;
          margin-right: 5px;
          font-size: 16px;
        }
      }
    }
    .right {
      float: none;
      display: block;
    }
    .select_journey {
      .sel {
        select {
          padding: 14px 25px;
          width: 180px;
        }
      }
    }
  }

  .txt_top {
    width: auto;
    h1 {
      font-size: 35px;
    }
    p {
      font-size: 15px;
    }
  }
  .why_list {
    .box {
      width: 32%;
      margin-right: 2%;
      margin-bottom: 50px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .ico {
        img {
          width: 55px;
        }
      }
      .dec {
        h3 {
          font-size: 14px;
          letter-spacing: 1px;
        }
        p {
          font-size: 15px;
        }
      }
    }
  }

  .home_service {
    .nav_servive {
      .list {
        a {
          font-size: 14px;
          padding: 8px 15px;
        }
      }
    }
    .content_service {
      width: auto;
    }
    .slider_service,
    .list_desti {
      .slide {
        margin-right: 15px !important;
      }
      &.slide_Package {
        .item {
          width: 350px;
          margin-right: 30px !important;
        }
      }
    }
  }
  .slider_service,
  .list_desti {
    .slide {
      width: 230px;
      margin-right: 15px;
      .img {
        .hov_see {
          .btn_round {
            width: 150px;
          }
        }
      }
      &:nth-of-type(4n) {
        margin-right: 15px;
      }
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
  }
  .slider_service {
    &.slide_Package {
      .item {
        width: 350px;
        margin-right: 20px;
      }
    }
  }
  .recomend_list {
    .item {
      width: 350px;
      margin-right: 20px;
      &:nth-child(4n),
      &:nth-child(3n) {
        margin-right: 20px;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
  .list_page {
    .recomend_list {
      margin-bottom: 30px;
    }
  }
  .sec_recomend {
    .recomend_list {
      .item:nth-of-type(3) {
        display: none;
      }
    }
    .wrapper > h3 {
      font-size: 24px;
    }
  }

  .slider_service {
    .slick-arrow {
      width: 40px;
      height: 40px;
      background: #fff;
      padding: 0;
      top: 30%;
      &:before {
        content: "";
        width: 20px;
        height: 10px;
        background: url("../images/material/arr_slider.png") no-repeat top left;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      &:after {
        background-position: bottom left;
        width: 0px;
        height: 10px;
        left: auto;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &:hover {
        &:after {
          width: 20px;
        }
      }
      &.slick-prev {
        left: -20px;
      }
      &.slick-next {
        right: -20px;
      }
    }
  }

  .home_travel {
    > img {
      object-position: right;
    }
    .text_travel {
      h3 {
        font-size: 20px;
        line-height: 30px;
      }
      .link_btn {
        margin-top: 40px;
      }
    }
  }
  .home_testimonial {
    .slider_testimo {
      width: 550px;
    }
  }

  /* middle
----------------------------------------------------------------------------------------------*/
  .breadcrumb {
    > a {
      font-size: 13px;
    }
  }
  .middle {
    padding: 70px 0 50px;
    .txt_top {
      padding: 80px 0 40px;
    }
  }
  .text_inner {
    padding-bottom: 60px;
    .lf_txt {
      width: 230px;
      h3 {
        font-size: 20px;
        line-height: 30px;
      }
      h4 {
        font-size: 16px;
      }
      h4.title {
        font-size: 18px;
      }
      p {
        margin-right: 20px;
        font-size: 15px;
      }
    }
    .rh_txt {
      width: 475px;
      ol,
      ul {
        li {
          line-height: 24px;
          &:after {
            top: 10px;
          }
        }
      }
    }
  }
  .sec_service {
    padding: 90px 0 40px;
    .wrapper {
      width: 720px;
    }
    .blok_service {
      .img_lf {
        width: 360px;
      }
      .right_dec {
        width: 325px;
      }
    }
    .slider_partner {
      width: auto;
      margin: -20px 45px 0;
      .slick-arrow {
        &.slick-prev {
          left: -40px;
        }
        &.slick-next {
          right: -40px;
        }
      }
    }
  }

  .form_box {
    .row {
      margin-bottom: 20px;
      label {
        font-size: 15px;
        margin-top: 15px;
      }
      .field {
        input[type="text"],
        input[type="email"],
        input[type="password"],
        input[type="number"],
        select,
        textarea {
          padding: 10px 20px;
        }
        .ck_interest {
          li {
            span {
              font-size: 15px;
              line-height: 26px;
            }
          }
        }
        .col_ck {
          &.phone_numb {
            .txt_type {
              p {
                margin-bottom: 15px;
              }
            }
            .drop_numb {
              .select_numb {
                > input {
                  padding-left: 100px;
                }
                .selectcustom {
                  width: 95px;
                  .btnselect {
                    line-height: 42px;
                    font-size: 15px;
                    padding-left: 40px;
                    .img_ico {
                      width: 20px;
                      height: 20px;
                    }
                  }
                  .dropselect {
                    .optselect {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .btn_round {
        min-width: 150px;
      }
      &.sum .field.full .block h6 {
        padding-right: 0;
        width: 95px;
      }
    }
    &.plan {
      .row {
        .field {
          .ck_interest {
            li {
              margin-bottom: 6px;
              span {
                font-size: 15px;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
  }
  .thanks_pg {
    padding-top: 70px;
    .img {
      overflow: hidden;
      img {
        max-width: none;
        width: auto;
        left: -10%;
        position: relative;
      }
    }
    .text_thanks {
      h1 {
        font-size: 45px;
      }
    }
  }
  .list_result {
    .item {
      .dec_result {
        width: 395px;
        p {
          font-size: 15px;
          line-height: 22px;
          height: 115px;
          overflow: hidden;
        }
      }
    }
  }

  .banner {
    margin-bottom: 50px;
    #map_contact {
      height: 300px;
    }
  }
  .banner_top {
    .capt {
      width: auto;
      left: 50px;
      right: 50px;
      h6 {
        font-size: 16px;
      }
      h1 {
        font-size: 45px;
        line-height: 55px;
      }
    }
  }
  .bnnr_dash {
    margin: 0 -40px;
    .img {
      height: 180px;
    }
  }

  .nav_destination {
    .list {
      a {
        padding: 8px 15px 25px;
        margin-right: 10px;
      }
    }
  }

  .detail_sec {
    .text_article {
      width: auto;
    }
    &.text_tmt {
      background-position: 70% center;
      .dec_txt {
        padding-top: 40px;
        h2 {
          width: 80%;
        }
      }
    }
  }
  .item_detail {
    .box {
      width: 31.25%;
      margin-right: 3.125%;
      &:nth-of-type(3) {
        margin-right: 0;
      }
      &:nth-of-type(4) {
        display: none;
      }
    }
  }
  .map_info {
    .map_content {
      width: 350px;
    }
    .info {
      width: 350px;
    }
  }
  #map_location {
    height: 250px;
  }

  .package_sec {
    .left_package {
      width: 390px;
      p {
        font-size: 14px;
        line-height: 22px;
      }
      .box_checkout {
        .boxs {
          h3 {
            font-size: 20px;
          }
          padding: 30px 25px;
          .form_box {
            .row {
              .field {
                margin-left: 130px;
                input[type="text"],
                input[type="email"],
                input[type="password"],
                input[type="number"],
                select,
                textarea {
                  font-size: 15px;
                }
              }
            }
          }
          &.white {
            padding: 0 20px;
            .btn_round {
              display: block;
            }
          }
        }
      }
    }
    .right_package {
      width: 270px;
      .box {
        .label_info {
          padding: 15px;
          h3 {
            padding-left: 28px;
          }
        }
        .isi_info {
          padding: 15px;
          > p {
            font-size: 14px;
          }
          .col {
            margin-bottom: 20px;
            label {
              float: none;
              display: inline-block;
            }
            p {
              margin-left: 0;
              font-weight: 600;
            }
          }
          ul {
            li {
              font-size: 14px;
            }
          }
        }
        &.book_now {
          .isi_info {
            padding: 15px;
            .booknow_info {
              .row {
                .colmn {
                  .qty_input {
                    padding: 0 25px;
                    button {
                      &.plus {
                        width: 25px;
                        height: 25px;
                        background-size: 100%;
                      }
                      &.min {
                        width: 25px;
                        height: 25px;
                        background-size: 100%;
                      }
                    }
                    .input_val {
                      width: 35px;
                      font-size: 18px;
                    }
                  }
                }
              }
              &.ckout {
                .colmn {
                  h6 {
                    text-align: left;
                    font-weight: 600;
                  }
                  &:nth-child(1) {
                    width: 75%;
                    margin-right: 10px;
                  }
                  &:nth-child(2) {
                    width: 20%;
                    margin-right: 0;
                  }
                  &:nth-child(3) {
                    text-align: left;
                  }
                }
              }
              &.inp {
                .colmn {
                  &:nth-of-type(2) {
                    text-align: left;
                    width: 90px;
                  }
                  &:nth-of-type(3) {
                    width: 138px;
                  }
                }
              }
            }
          }
          .total {
            padding: 20px 15px;
            margin: 0 -15px -20px;
            .col {
              text-align: center;
              label {
                font-size: 15px;
                font-weight: 600;
                float: left;
              }
              p {
                margin-left: 70px;
                font-size: 16px;
              }
            }
          }
        }
        &.grey_bx {
          .txt {
            p {
              font-size: 14px;
            }
          }
          .isi_info {
            h5 {
              font-size: 15px;
              a {
                font-size: 18px;
              }
            }
            > label {
              font-size: 14px;
            }
          }
        }
      }
    }
    .tab_content {
      .dec_tab {
        .drop_txt {
          &.active {
            max-height: 8000px;
          }
        }
        ul {
          li {
            font-size: 14px;
            line-height: 22px;
            &:before {
              top: 6px;
            }
          }
        }
      }
    }
  }
  .qty_input {
    width: 35px;
    padding: 0 25px;
    button {
      &.plus {
        width: 25px;
        height: 25px;
        background-size: 100%;
      }
      &.min {
        width: 25px;
        height: 25px;
        background-size: 100%;
      }
    }
    .input_val {
      width: 35px;
    }
  }

  .tab_packages {
    .nav {
      a {
        margin-right: 30px;
      }
    }
    .tab_content {
      .dec_tab {
        table {
          thead {
            th {
              height: 40px;
              &:nth-of-type(1) {
                padding-left: 20px;
              }
              &:last-of-type {
                padding-right: 20px;
              }
            }
          }
          tbody {
            td {
              &:nth-of-type(1) {
                padding-left: 20px;
              }
              &:last-of-type {
                padding-right: 20px;
              }
            }
          }
        }
        .list_diagram {
          .info_tour {
            padding-left: 85px;
            p {
              font-size: 14px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }

  .signin_pg {
    padding-top: 70px;
  }
  .form_sign {
    width: 370px;
    input[type="email"],
    input[type="password"] {
      padding: 12px 25px;
    }
    .link_btn > span {
      font-size: 16px;
      line-height: 35px;
    }
  }
  .box_signup {
    @include borderRadius(4px);
    .left.img {
      display: none;
    }
    .right_form {
      float: none;
      width: 500px;
      padding: 70px 0 40px;
      margin: 0 auto;
    }
  }
  .form_box.register {
    margin-top: 0;
  }
  .list_step {
    width: 720px;
    .step {
      padding: 10px 10px 10px 20px;
      &:nth-of-type(1) {
        padding-left: 30px;
      }
      &:last-of-type {
        padding-right: 30px;
      }
    }
  }

  .pg_dashboard {
    .block_lf {
      width: 270px;
    }
    .block_rh {
      width: 430px;
      .list_trip {
        .box_trip {
          .text_trip {
            h6 {
              margin-bottom: 10px;
            }
            p {
              margin-bottom: 15px;
            }
          }
        }
      }
      .box {
        .label_info {
          a {
            top: 18px;
            right: 20px;
          }
        }
        .isi_info {
          .col {
            label {
              max-width: none;
              display: block;
              margin-bottom: 5px;
            }
            p {
              margin-left: 0;
            }
          }
        }
      }
      .select2-container--default
        .select2-selection--multiple
        .select2-selection__choice {
        font-size: 12px;
      }
    }
    .box {
      .label_info {
        padding: 10px 20px;
      }
      .isi_info {
        padding: 15px;
        .col {
          margin-bottom: 20px;
          label {
            float: none;
            display: block;
          }
          p {
            margin-left: 0;
          }
          &.link_edit {
            margin: 0 -15px -10px;
            .edit {
              padding: 10px;
              font-size: 13px;
            }
          }
        }
        table tbody td {
          display: inline-block;
          padding: 5px;
          &:nth-child(1) {
            width: 100%;
            display: block;
            padding-bottom: 10px;
          }
          &:nth-child(2) {
            width: 53%;
            margin-right: -1px;
            padding-bottom: 15px;
          }
          &:nth-child(3) {
            width: 37%;
            padding-bottom: 15px;
          }
        }
        .list-box {
          width: 230px;
          .toggle {
            h3 {
              background-size: 25px;
              padding-top: 40px;
            }
            .img {
              width: 230px;
              height: 230px;
            }
          }
          .box {
            width: 230px;
            .img {
              height: auto;
            }
            a {
              font-size: 15px;
              background-size: 15px;
            }
          }
        }
      }
    }
  }
  .box_booking {
    .item_booking {
      .text_booking {
        .tgl {
          font-size: 14px;
          margin-bottom: 10px;
          i {
            display: block;
            margin-top: 6px;
          }
        }
        .ket_detail {
          .col {
            label {
              font-size: 16px;
              line-height: 24px;
            }
            p {
              font-size: 16px;
              line-height: 24px;
              margin-left: 160px;
            }
            ul {
              margin-left: 160px;
              li {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
        .link {
          a.back {
            font-size: 16px;
          }
        }
      }
    }
  }

  #pop_hotel {
    .pop_inner {
      .content_pop {
        width: 700px;
        padding: 45px 30px 50px;
        .close {
          width: 35px;
          height: 35px;
          span {
            width: 22px;
            top: 16px;
            left: 7px;
          }
        }
        .top_popup {
          h3 {
            font-size: 22px;
          }
          .star {
            span {
              margin-bottom: 10px;
            }
          }
        }
        .content_resort {
          .left {
            h4 {
              font-size: 14px;
            }
            p {
              font-size: 14px;
              line-height: 22px;
            }
            ul {
              li {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  /* footer
----------------------------------------------------------------------------------------------*/
  footer {
    .top_foot {
      .box:nth-of-type(1) {
        margin-right: 30px;
      }
      .mnu_foot {
        display: none;
      }
      .follow {
        float: right;
        display: flex;
        .item {
          float: left;
          &:nth-child(2) {
            margin-left: 30px;
            margin-right: 10px;
          }
        }
        .ico_phone {
          font-size: 16px;
        }
      }
    }
    .btm_foot {
      height: 60px;
      padding-top: 20px;
      .left {
        p {
          font-size: 12px;
        }
      }
      .right {
        a {
          display: inline-block;
          line-height: 20px;
          font-size: 12px;
          margin-right: 15px;
        }
      }
    }
  }
}
